<template>
  <draggable
    :group="activityId"
    v-bind="dragOptions"
    tag="div"
    class="item-container pl-3"
    :list="list"
    :value="value"
    @input="emitter"
    handle=".handle"
  >
    <div
      :key="el.id"
      v-for="el in realValue"
    >
      <tr 
        style="border: 1px solid"
        class="border-light"
        v-on:mouseover="onMouseOver(el)"
        v-on:mouseleave="onMouseLeft(el)"
      >
        <td
          class="pt-6 text-right bg-white"
          style="width: 35px"
        >
          <i v-if="currentUser.menu_items.includes('AddEntity')" class="fa fa-align-justify handle"></i>
        </td>
        <td
          class="pt-6 text-right bg-white"
          style="width: 35px"
        >
          <i
            :class="icon(el)"
            v-b-tooltip.hover.right
            :title="$t(iconLabel(el))"
          >
          </i>
        </td>
        <td
          class="px-2 py-3 d-table-cell bg-white"
          :style="'width: ' + titleColumnWidth + 'px'"
        >
          <router-link
            v-if="currentUser.menu_items.includes('AddEntity')"
            :to="{
              name: (el.system_activity_type_id === 8) ? `edit_markup_legacy` : `edit_${el.activity_type.name
                .toLowerCase()
                .replace(/ /g, '_')}`,
              params: { activity: el.id }
            }"
            class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
          >
            {{ el.title }}
          </router-link>
          <span
            v-else
            class="text-dark-65 font-weight-bolder mb-1 font-size-sm"
          >
            {{ el.title }}
          </span>
          <span class="d-block">
            <p
              :id="'allocation_text' + el.id"
              class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
              style="cursor:pointer !important"
            >
              {{ activityAllocationText(el) }}
            </p>
            <p
              class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mx-1 mb-0"
            >
              -
            </p>
            <p
              class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
            >
              {{ $t("GENERAL.TEXT.LAST_UPDATE") }}:
              <GenericDatetimeDisplay
                :date="el.updated_at"
                :lastUpdated="true"
                :relative="false"
              >
              </GenericDatetimeDisplay>
            </p>
          </span>
        </td>
        <td
          class="px-2 py-5 d-table-cell bg-white"
          style="width: 350px"
        >
          <div
            v-if="el.sequence_type == 2"
            :id="'date_text_scheduled' + el.id"
            class="d-inline-block"
            style="cursor:pointer"
          >
            <span
              class="d-block text-dark-65 mb-1 font-size-sm"
              :class="formattedDates(el).stageClass"
            >
              <strong>{{ $t("GENERAL.PREFIX.SCHEDULED_FROM") }}</strong>
              <GenericDatetimeDisplay
                :long="true"
                :date="el.starts_on"
                :lastUpdated="false"
                :relative="false"
              >
              </GenericDatetimeDisplay>
            </span>
          </div>
          <div
            v-else-if="el.sequence_type == 1"
            :id="'date_text_sequenced' + el.id"
            class="d-inline-block"
            style="cursor:pointer"
          >
            <span
              class="d-block text-dark-65 mb-1 font-size-sm"
              :class="formattedDates(el).stageClass"
            >
              <strong>{{ $t("GENERAL.TEXT.SEQUENCED") }}</strong>
            </span>
          </div>
        </td>
        <td
          class="px-2 text-right d-table-cell bg-white"
          style="width: 565px"
        >
          <div v-show="optionsEnabled[el.id] == 1" class="pt-1 px-3">
            <a
              :href="calculateTestActivityLink(el.id)" 
              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-5"
              target="_blank"
              v-b-tooltip.hover.bottom
              :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.TEST')"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Media/Play.svg" />
              </span>
            </a>
            <span>
              <a v-if="currentUser.menu_items.includes('AddEntity')">
                <b-dropdown
                  size="sm"
                  class="sequence-options btn btn-icon btn-light btn-sm"
                  v-b-tooltip.hover.top
                  right
                  v-if="optionsEnabled[el.id] == 1"
                  :ref="'sequence-dropdown-' + el.id"
                  :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.SEQUENCE_OPTIONS')"
                >
                  <template #button-content>
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="media/svg/icons/Home/Commode2.svg"/>
                    </span>
                  </template>
                  <b-dropdown-item-button  
                    @click="sequenceActivity(el)"
                  > 
                    <span class="svg-icon svg-icon-md svg-icon-primary mr-2">
                      <inline-svg src="media/svg/icons/Code/Plus.svg"/>
                    </span>
                    <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.SEQUENCE_ANOTHER') }} </span>
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    @click="removeFromSequence(el)"
                  > 
                    <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                      <inline-svg src="media/svg/icons/Navigation/Minus.svg"/>
                    </span>
                    <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.REMOVE_FROM_SEQUENCE') }} </span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button
                    v-if="el.child_activities.length > 0"
                    @click="removeAllFromSequence(el)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                      <inline-svg src="media/svg/icons/Home/Library.svg"/>
                    </span>
                    <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.REMOVE_ALL_FROM_SEQUENCE') }} </span>
                  </b-dropdown-item-button>
                  <b-dropdown-divider v-if="el.child_activities.length > 0"></b-dropdown-divider>
                  <b-dropdown-item-button
                    v-if="el.child_activities.length > 0"
                    @click="deleteSequence(el)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                      <inline-svg src="media/svg/icons/Code/Error-circle.svg"/>
                    </span>
                    <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DESTROY_SEQUENCE_CHILD') }} </span>
                  </b-dropdown-item-button>
                </b-dropdown>
              </a>
            </span>
            <a
              href="javascript:void(0)"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
              v-if="currentUser.menu_items.includes('AddEntity')"
              v-b-tooltip.hover.bottom
              @click="duplicateActivity(el)"
              :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DUPLICATE')"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/General/Duplicate.svg" />
              </span>
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
              v-if="currentUser.menu_items.includes('AddEntity')"
              v-b-tooltip.hover.bottom
              :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DESTROY')"
              @click="deleteActivity(el)"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="media/svg/icons/Home/Trash.svg" />
              </span>
            </a>
          </div>
        </td>

        <b-tooltip
        v-if="el.project_segments.length > 0"
        :ref="'tooltip' + el.id"
        :target="'allocation_text' + el.id"
        placement="right"
      >
        <template v-for="(project_segment, i) in el.project_segments">
          <div :key="i">{{ project_segment.name }}<br /></div>
        </template>
      </b-tooltip>

      <b-tooltip
        v-if="el.project_segments.length == 0"
        :ref="'tooltip' + el.id"
        :target="'allocation_text' + el.id"
        placement="right"
      >
        {{ $t("TABLES.ACTIVITY_BREAKDOWN_TEXT.OPEN_TO_ALL") }}
      </b-tooltip>
      </tr>
      <DraggableActivity
        class="item-sub"
        :list="el.child_activities"
        :generation="generation + 1"
        :activityId="activityId"
        @open_sequence_modal="$emit('open_sequence_modal', $event)"
        @activity_list_edited="$emit('activity_list_edited')"
      >
      </DraggableActivity>
    </div>
  </draggable>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";
import DraggableActivity from "@/view/content/activities/DraggableActivity";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

import {
  DELETE_PROJECT_ACTIVITY,
  DELETE_PROJECT_ACTIVITY_SEQUENCE,
  UPDATE_PROJECT_ACTIVITY_SEQUENCE
} from "@/core/services/store/activity/activity.module";

export default {
  name: "DraggableActivity",

   data() {
    return {
      optionsEnabled: []
    };
  },
  
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    onMouseOver(activity) {
      this.$set(this.optionsEnabled, activity.id, 1);
    },
    onMouseLeft(activity) {
      this.$set(this.optionsEnabled, activity.id, 0);
    },
    sequenceActivity(activity) {
      this.$emit("open_sequence_modal", activity.id);
    },
    duplicateActivity(activity) {
      let duplicateRoute =
        "duplicate_" + ((this.activity.recording_override) ? 'screen_recording' : this.activity.activity_type.name)
          .toLowerCase()
          .split(" ")
          .join("_");

      this.$router.push({
        name: duplicateRoute,
        params: { activity: activity.id }
      });
    },
    removeFromSequence(activity) {
      this.$swal
        .fire({
          title: `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.TITLE")}`,
          text:
            activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: activity.id,
              data: {
                remove_all: false
              }
            };
            this.$store
              .dispatch(UPDATE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_SUCCESS.TITLE"
                  )}`,
                  text:
                    activity.title +
                    " " +
                    `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_SUCCESS.MESSAGE")}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    removeAllFromSequence(activity) {
      this.$swal
        .fire({
          title: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.TITLE"
          )}`,
          text:
            activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: activity.id,
              data: {
                remove_all: true
              }
            };
            this.$store
              .dispatch(UPDATE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_ALL_SUCCESS.TITLE"
                  )}`,
                  text:
                    activity.title +
                    " " +
                    `${this.$t(
                      "SWEETALERTS.ACTIVITY_REMOVE_ALL_SUCCESS.MESSAGE"
                    )}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_ALL_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    deleteSequence(activity) {
      this.$swal
        .fire({
          title: `${this.$t(
            "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.TITLE"
          )}`,
          text:
            activity.title +
            " " +
            `${this.$t(
              "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.MESSAGE"
            )}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: activity.id
            };
            this.$store
              .dispatch(DELETE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_SUCCESS.TITLE"
                  )}`,
                  text:
                    activity.title +
                    " " +
                    `${this.$t(
                      "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_SUCCESS.MESSAGE"
                    )}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    deleteActivity(activity) {
      this.$swal
        .fire({
          title: `${this.$t("SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.TITLE")}`,
          text:
            activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              projectId: activity.project_id,
              activityId: activity.id
            };
            this.$store
              .dispatch(DELETE_PROJECT_ACTIVITY, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_DELETE_SUCCESS.TITLE"
                  )}`,
                  text:
                    activity.title +
                    " " +
                    `${this.$t("SWEETALERTS.ACTIVITY_DELETE_SUCCESS.MESSAGE")}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_DELETE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    activityAllocationText(activity) {
      if (activity.project_segments.length == 0) {
        return "All Participants";
      } else {
        return "Allocation (" + activity.project_segments.length + ")";
      }
    },
    formattedDates(activity) {
      let stageClass = "";
      switch (activity.activity_stage.name) {
        case "Draft":
          stageClass = "";
          break;
        case "Published":
          stageClass = "";
          break;
        case "Active":
          stageClass = "text-success";
          break;
        case "Closed":
          stageClass = "text-danger";
          break;
      }
      return {
        stageClass: stageClass
      };
    },
    icon(activity) {
      return SystemIconHelper.activityType(activity.activity_type, activity.recording_override);
    },
    iconLabel: function(activity) {
      return SystemLabelHelper.activityType(activity.activity_type, activity.recording_override);
    },
    calculateTestActivityLink: function(activityId) {
      let subdomain = window.location.host.split('.')[0];
      let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
      return protocol + subdomain + "." + process.env.VUE_APP_INPUT_URL_SUFFIX + "/#/activity/" + activityId + "/view";
    }
  },
  components: {
    draggable,
    DraggableActivity,
    GenericDatetimeDisplay
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    dragOptions() {
      return {
        animation: 0,
        group: this.activityId,
        disabled: false,
        ghostClass: "ghost"
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
    titleColumnWidth() {
      return (765 - (this.generation * 8))
    },
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    generation: {
      required: true,
      type: Number
    },
    activityId: {
      required: true,
      type: Number
    }
  }
};
</script>