<template>
  <b-dropdown
    id="filter-activity-type"
    size="sm"
    :text="$t('SYSTEM.ACTIVITY_TYPE.' + selectedTypeName.toUpperCase())"
    variant="primary"
    class="table-filter"
    right
  >
    <b-dropdown-item @click="emitChange(null)">
      {{ $t('SYSTEM.ACTIVITY_TYPE.ANY') }}
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item
      v-for="(systemActivityType, i) in eligibleTypes"
      :key="i"
      @click="emitChange(systemActivityType)"
    >
      {{ $t('SYSTEM.ACTIVITY_TYPE.' + systemActivityType.name.split(' ').join('_').toUpperCase() ) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemActivityTypeFilter",

  props: {
    selected: { type: Number, required: false },
    omitTypes: { type: Array, required: false }
  },

  methods: {
    emitChange: function(stage = null) {
      if (stage != null) {
        this.$emit('type_changed',stage.id);
      } else {
        this.$emit('type_changed',null);
      }
    }
  },

  computed: {
    ...mapGetters(["systemActivityTypes"]),

    selectedTypeName: function() {
      let selected = this.selected;
      if (this.selected != undefined && this.selected != null) {
        let type = this.systemActivityTypes.filter(function (type) { return type.id == selected; });
        return type[0].name.split(' ').join('_');
      } else {
        return 'any';
      }
    },

    eligibleTypes: function() {
      if (this.omitTypes != undefined) {
        let omitTypes = this.omitTypes;
        return this.systemActivityTypes.filter(function (type) { return !omitTypes.includes(type.id); });

      } else {
        return this.systemActivityTypes;
      }
    }
  }
};
</script>
