<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('NAVIGATION.PROJECT_MENU.ACTIVITIES') }}
          </h3>
        </div>
      </div>
      <div class="card-body">
        <b-spinner
          v-if="!loadingComplete && !error.active"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <div
          v-if="loadingComplete && !error.active"
        >
          <div v-if="activityList.length > 0" class="d-flex justify-content-between mb-10">
            <div class="form-group mb-0 col-xl-6 px-0">
              <input
                id="project_report_table_search"
                v-model="search"
                type="text"
                :placeholder="$t('GENERAL.INPUT.SEARCH')"
                class="form-control form-control-solid h-auto py-3 px-5 w-75"
                :disabled="loadingComplete == false"
              />
            </div>
            <div>
              <FilterSystemActivityType
                class="mr-3"
                :selected="filters.type"
                :omitTypes="[9]"
                @type_changed="filters.type = $event"
              >
              </FilterSystemActivityType>
              <FilterSystemActivityStage
                :selected="filters.status"
                @stage_changed="filters.status = $event"
              >
              </FilterSystemActivityStage>
            </div>
          </div>
          <template v-for="(room, i) in calculatedProjectRooms">
            <div
              :key="i"
              v-if="loadingComplete && !error.active && activityList.length > 0"
              class="mb-10"
            >
              <table
                id="activity-table-room"
                v-if="roomActivities(room).length > 0"
                class="table table-head-custom table-vertical table-striped center table-hover"
                style="table-layout:auto; overflow: auto"
              >
                <tbody>
                  <template v-for="(activity, a) in roomActivities(room)">
                    <ActivityRoomRow
                      :key="a"
                      :activity="activity"
                      :generation="0"
                      :index="a"
                      @open_sequence_modal="openSequenceModal"
                      @duplicate_sequence="openDuplicateSequenceModal"
                      @activity_list_edited="activitiesChanged"
                      v-if="
                        activityInSearch(activity) &&
                        activityInStatusFilter(activity) &&
                        activityInTypeFilter(activity)
                      "
                    >
                    </ActivityRoomRow>
                  </template>
                </tbody>
              </table>
            </div>
          </template>
          <div
            v-if="loadingComplete && !error.active && activityList.length > 0"
            class="mb-10"
          >
            <table
              id="activity-table"
              v-if="roomActivities(null).length > 0"
              class="table table-head-custom table-vertical table-striped center table-hover"
              style="table-layout:auto; overflow: auto"
            >
              <tbody>
                <template v-for="(activity, a) in roomActivities(null)">
                  <ActivityRoomRow
                    :key="a"
                    :activity="activity"
                    :generation="0"
                    :index="a"
                    @open_sequence_modal="openSequenceModal"
                    @duplicate_sequence="openDuplicateSequenceModal"
                    @activity_list_edited="activitiesChanged"
                    v-if="
                      activityInSearch(activity) &&
                      activityInStatusFilter(activity) &&
                      activityInTypeFilter(activity)
                    "
                  >
                  </ActivityRoomRow>
                </template>
              </tbody>
            </table>
          </div>
          <b-alert v-if="activityList.length == 0 && loadingComplete" show variant="light">
            {{ $t('TABLES.ACTIVITY_BREAKDOWN_TEXT.NO_ACTIVITIES_2') }}
          </b-alert>
          <b-alert v-if="error.active" show variant="light">
            {{ error.message }}
          </b-alert>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" size="xl" hide-footer :title="$t('TABLES.ACTIVITY_MESSAGES.SEQUENCE_MODAL')">
      <div
        id="activity_sequence_selector"
        class="btn-group btn-group-sm w-100"
        role="group"
        style="box-shadow: none !important"
      >
        <template v-for="(systemActivityType, i) in systemActivityTypes">
          <router-link
            :to="{ name: getRouteName(systemActivityType), params: { activity: sequence_id }}"
            v-if="systemActivityType.can_sequence"
            :key="i"
            class="btn btn-outline-secondary bg-white px-3 py-5"
            style="border: none !important"
            :style="buttonWidth"
            v-b-tooltip.hover.bottom
            :title="$t('NAVIGATION.ACTIVITY_DESCRIPTION.' + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id))"
          >
            <i
              :class="icon(systemActivityType)"
              class="text-muted px-0 mb-3"
            >
            </i>
            <p class="font-size-sm mb-0">
              {{ $t('NAVIGATION.ADD_ACTIVITY.' + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id)) }}
            </p>
          </router-link>
        </template>
      </div>
    </b-modal>
    <b-modal ref="my-modal-duplicate" size="xl" hide-footer :title="$t('TABLES.ACTIVITY_MESSAGES.DUPLICATE_SEQUENCE_MODAL')">
      <div
        id="duplicate_sequence_options"
        style="box-shadow: none !important"
      >
        <div class="row">
          <InputActivityTitle
            class="col-xl-12 px-5 mb-10"
            :model="$v.parent_title"
            :submissionStates="submissionStates"
            :label="$t('FORMS.INPUTS.DUPLICATE_SEQUENCE_TITLE')"
          ></InputActivityTitle>
        </div>
        <div class="row">
          <InputGenericDatePicker
            class="col-xl-4 px-5 mb-5"
            :model="$v.sequence_start_date"
            id="activity-sequence-start-dates"
            :label="$t('FORMS.INPUTS.ACTIVITY_START_DATE')"
            :error="$t('FORMS.ERROR.ACTIVITY_START_DATE')"
            :submissionStates="submissionStates"
            type="datetime"
            @date_updated="sequence_start_date = $event"
            :notBefore="projectInfo.starts_on"
            :notAfter="projectInfo.ends_on"
          >
          </InputGenericDatePicker>
          <InputGenericDatePicker
            class="col-xl-4 ml-5 px-5 mb-5"
            :model="$v.sequence_end_date"
            id="activity-sequence-end-dates"
            :label="$t('FORMS.INPUTS.ACTIVITY_END_DATE')"
            :error="$t('FORMS.ERROR.ACTIVITY_END_DATE')"
            :submissionStates="submissionStates"
            type="datetime"
            @date_updated="sequence_end_date = $event"
            :notBefore="projectInfo.starts_on"
            :notAfter="projectInfo.ends_on"
          >
          </InputGenericDatePicker>
        </div>
        <div class="row">
          <div class="card-header mb-5 ml-1">
            <div class="card-title mb-0">
              <h5 class="card-label">
                {{ $t('FORMS.INPUTS.DUPLICATE_SEQUENCE_SEGMENTS') }}  
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- Here be dragons - submissionStates manually set to false for now -->
          <InputAdvancedSegmentSelect
            id="input-segments"
            class="px-5 mb-5 align:right"
            :submissionStates="{ submitting: false }"
            children_key="project_segments"
            label_key="name"
            :options="projectSegmentations"
            :initialSelection="initial_selection"
            @children_changed="sequence_segments = $event"
            @clear_segments="sequence_segments = []"
            @initial_selection_changed="initial_selection = $event"
          >
          </InputAdvancedSegmentSelect>
        </div>
        <div class="row">
          <div class="card-footer d-flex justify-content-between my-0 py-4 w-100">
            <div></div>
              <div class="justify-content-end">
                <button
                  @click="onDuplicateSequence()"
                  class="btn btn-primary mr-2"
                >
                  {{ $t("GENERAL.TEXT.DUPLICATE") }}
                </button>
              </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required
} from "vuelidate/lib/validators";

import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { DUPLICATE_PROJECT_ACTIVITY_SEQUENCE } from "@/core/services/store/activity/activity.module";

import ActivityRoomRow from "@/view/content/activities/ActivityRoomRow";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import FilterSystemActivityStage from "@/view/components/filters/SystemActivityStage";
import FilterSystemActivityType from "@/view/components/filters/SystemActivityType";
import InputGenericDatePicker from "@/modules/together-helpers/components/generic-inputs/GenericDatePicker";
import InputActivityTitle from "@/view/components/form-inputs/ActivityTitle";
import InputAdvancedSegmentSelect from "@/view/components/form-inputs/AdvancedSegmentSelect";

export default {
  mixins: [validationMixin],

  name: "ActivityRoomBreakdown",

  props: {
    projectInfo: { type: Object, required: true },
    pageLoader: { type: Object, required: true },
    error: { type: Object, required: true },
    projectRooms: { type: Array, required: true },
    activityList: { type: Array, required: true },
    roomlessActivityCount: { type: Number, required: true },
    systemActivityTypes: { type: Array, required: true },
    systemActivityStages: { type: Array, required: true }
  },

  data() {
    return {
      search: "",
      sequence_id: null,
      parentActivity: null,
      parent_title: null,
      sequence_start_date: null,
      sequence_end_date: null,
      initial_selection: [],
      sequence_segments: [],
      filters: {
        status: null,
        type: null
      },
      submissionStates: {
        submitting: false
      },
    };
  },

  validations: {
    parent_title: {
      required
    },
    sequence_start_date: {
      maxValue(val, { sequence_end_date }) {
        return new Date(val) < new Date(sequence_end_date);
      }
    },
    sequence_end_date: {},
  },

  components: {
    ActivityRoomRow,
    FilterSystemActivityStage,
    FilterSystemActivityType,
    InputGenericDatePicker,
    InputActivityTitle,
    InputAdvancedSegmentSelect
  },

  mounted() {
    this.pageLoader.componentsRequired++;
    this.fetchProjectSegmentations();  
  },

  methods: {
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          projectSegments: "with",
          paginate: false
        }
      };
     
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    icon: function(activityType) {
      return SystemIconHelper.activityType(activityType, false);
    },
    roomActivities: function(room = null) {
      if (room != null) {
        return this.activityList.filter(function(activity) {
          return activity.project_room_id == room.id;
        });
      } else {
        return this.activityList.filter(function(activity) {
          return activity.project_room_id == null;
        });
      }
    },
    openSequenceModal: function(value) {
      this.$refs['my-modal'].show()
      this.sequence_id = value;
    },
    openDuplicateSequenceModal: function(value) {
      this.$refs['my-modal-duplicate'].show()
      this.parentActivity = value;
      this.parent_title = value.title;
      value.project_segments.forEach(segment => {
        let seg = { parent: segment.project_segmentation_id, id: segment.id }
        this.initial_selection.push(seg);
      });
    },
    onDuplicateSequence() {
      this.$refs['my-modal-duplicate'].hide();
      let payload = {
          activityId: this.parentActivity.id,
          data: {
            parent_title: this.parent_title,
            start_date: this.sequence_start_date,
            end_date: this.sequence_end_date,
            segments: this.sequence_segments
          }
        }

        this.$store
          .dispatch(DUPLICATE_PROJECT_ACTIVITY_SEQUENCE, payload)
          .then(() => {
            this.$emit('activity_list_changed');
          })
    },
    activitiesChanged() {
      this.$emit('activity_list_changed');
    },
    getRouteName(systemActivityType) {
      let safeActivityName = systemActivityType.name.replace(/ /g,"_");
      return "add_" + safeActivityName.toLowerCase()
    },
    activityInSearch: function(activity) {
      let title = activity.title.toLowerCase();
      let type = activity.activity_type.name.toLowerCase();
      let stage = activity.activity_stage.name.toLowerCase();

      if (
        title.includes(this.search.toLowerCase()) ||
        type.includes(this.search.toLowerCase()) ||
        stage.includes(this.search.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    activityInStatusFilter: function(activity) {
      if (this.filters.status == null) {
        return true;
      } else {
        if (this.filters.status == activity.activity_stage.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    activityInTypeFilter: function(activity) {
      if (this.filters.type == null) {
        return true;
      } else {
        if (this.filters.type == activity.activity_type.id) {
          return true;
        } else {
          return false;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      "projectSegmentations"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired &&
        this.error.active == false &&
        this.pageLoader.fetchingActivities == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    buttonWidth: function() {
      return "width: " + 100 / this.systemActivityTypes.length + "%";
    },
    calculatedProjectRooms: function() {
      if (this.projectRooms.length > 0) {
        return this.projectRooms[0].project_rooms;
      } else {
        return [];
      }
    }
  }
};
</script>
