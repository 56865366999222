<template>
  <div
    class="w-100"
    v-if="loadingComplete"
  >
    <div
      id="new_activity_selector"
      class="btn-group btn-group-lg w-100 gutter-b"
      role="group"
      aria-label="New Activity Selector"
      v-if="loadingComplete"
    >
      <template v-for="(systemActivityType, i) in supportedActivityTypes">
        <router-link
          tag="a"
          :to="{ name: getRouteName(systemActivityType, i)}"
          :key="i"
          class="btn btn-outline-secondary bg-white px-3 py-5"
          :style="buttonWidth"
          v-b-tooltip.hover.bottom
          :title="$t('NAVIGATION.ACTIVITY_DESCRIPTION.' + getActivityTypeDescription(i, systemActivityType.id))"
        >
          <i
            :class="icon(systemActivityType, (i == 8) ? true : false)"
            class="text-muted px-0 mb-3"
          >
          </i>
          <p class="font-size-sm mb-0">
            {{ $t('GENERAL.BUTTONS.ADD') }}
            {{ $t(label(systemActivityType, (i == 8) ? true : false)) }}
          </p>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";

export default {
  name: "NewActivitySelector",

  props: [ "systemActivityTypes", "pageLoader", "error" ],

  methods: {
    icon: function(systemActivityType, recordingOverride) {
      return SystemIconHelper.activityType(systemActivityType, recordingOverride)
    },
    label: function(systemActivityType, recordingOverride) {
      return SystemLabelHelper.activityType(systemActivityType, recordingOverride)
    },
    getRouteName(systemActivityType, i) {
      let safeActivityName = "";

      if (i == 8) {
        safeActivityName = "screen_recording";
      } else {
        safeActivityName = systemActivityType.name.replace(/ /g,"_").replace("(","").replace(")","");
      }

      return "add_" + safeActivityName.toLowerCase()
    },
    getActivityTypeDescription: function(index, activityTypeId) {
      if (index == 8) {
        return "SCREEN";
      } else if ([8,10].includes(activityTypeId)) {
        return 8;
      } else {
        return activityTypeId;
      }
    }
  },

  computed: {
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired &&
        this.pageLoader.fetchingActivities == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    buttonWidth: function() {
      return "width: " + 100 / this.systemActivityTypes.length + "%";
    },
    supportedActivityTypes: function() {
      let baseEligible = this.systemActivityTypes.filter(function( systemActivityType ) {
        return ![8,9].includes(systemActivityType.id);
      });

      let externalType = this.systemActivityTypes.filter(function( systemActivityType ) {
        return systemActivityType.id == 7;
      });

      baseEligible.push(externalType[0]);

      return baseEligible;
    }
  }
}
</script>
