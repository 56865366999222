<template>
  <div>
    <div class="row mx-0 mb-20">
      <NewActivitySelector
        :pageLoader="pageLoader"
        :error="error"
        :systemActivityTypes="systemActivityTypes"
        v-if="currentUser.menu_items.includes('AddEntity')"
      >
      </NewActivitySelector>
      <div class="col-12 px-0">
        <ActivityRoomBreakdown
          :projectInfo="projectInfo"
          :pageLoader="pageLoader"
          :error="error"
          :projectRooms="projectRooms"
          :activityList="nonPollActivities"
          :roomlessActivityCount="roomlessActivityCount"
          :systemActivityTypes="systemActivityTypes"
          :systemActivityStages="systemActivityStages"
          @activity_list_changed="fetchActivities"
        >
        </ActivityRoomBreakdown>
        <ActivityPollBreakdown
          :pageLoader="pageLoader"
          :error="error"
          :pollActivities="pollActivities"
          @activity_list_changed="fetchActivities"
        >
        </ActivityPollBreakdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/system/breadcrumbs.module";
import { POPULATE_PROJECT_ROOMS_LIST } from "@/core/services/store/project/project_room.module";
import { POPULATE_EXTENDED_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";
import {
  POPULATE_ACTIVITY_TYPES,
  POPULATE_ACTIVITY_STAGES
} from "@/core/services/store/system/system.module";

import NewActivitySelector from "@/view/pages/project_management/activities/NewActivitySelector";
import ActivityRoomBreakdown from "@/view/pages/project_management/activities/ActivityRoomBreakdown";
import ActivityPollBreakdown from "@/view/pages/project_management/activities/ActivityPollBreakdown";

export default {
  name: "ProjectActivities",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 4,
        fetchingActivities: true
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  components: {
    NewActivitySelector,
    ActivityRoomBreakdown,
    ActivityPollBreakdown
  },

  mounted() {
    let protocol = process.env.NODE_ENV == "local" ? "http://" : "https://";
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.$t("NAVIGATION.ROOT_MENU.CLIENTS")}`, route: protocol + 'admin.' + process.env.VUE_APP_API_ROOT + "/#/clients/view" },
      { title: this.clientInfo.name, route: protocol + this.clientInfo.key + ".admin." + process.env.VUE_APP_API_ROOT + "/#/client/projects" },
      {
        title: this.projectInfo.name,
        route: "/project/view"
      },
      { title: `${this.$t("NAVIGATION.PROJECT_MENU.ACTIVITIES")}` }
    ]);

    this.fetchActivityStages();
    this.fetchActivityTypes();
    this.fetchProjectRooms();
    this.fetchActivities();
  },

  methods: {
    fetchActivityStages: function() {
      this.$store
        .dispatch(POPULATE_ACTIVITY_STAGES, false)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the available activity stages, please check back later or contact the helpdesk";
        });
    },
    fetchActivityTypes: function() {
      this.$store
        .dispatch(POPULATE_ACTIVITY_TYPES, false)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the available activity types, please check back later or contact the helpdesk";
        });
    },
    fetchProjectRooms: function() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          projectRooms: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_PROJECT_ROOMS_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project rooms list, please check back later or contact the helpdesk";
        });
    },
    fetchActivities: function() {
      this.pageLoader.fetchingActivities = true;
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
          starts_on: 'DESC'
        }
      };
      this.$store
        .dispatch(POPULATE_EXTENDED_ACTIVITY_LIST, payload)
        .then(() => {
          let removeActivities = [];
          this.activityList.forEach(activity => {
            if (activity.sequence_parent != null) {
              var activityIndex = this.activityList.findIndex(function(
                projectActivity
              ) {
                return projectActivity.id == activity.id;
              });
              if (activityIndex > -1) {
                removeActivities.push(activityIndex);
              }
            }
          });

          for (var i = removeActivities.length - 1; i >= 0; i--)
            this.activityList.splice(removeActivities[i], 1);

          this.pageLoader.componentsCompleted++;
          this.pageLoader.fetchingActivities = false;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters([
      "systemActivityStages",
      "systemActivityTypes",
      "currentUser",
      "clientInfo",
      "projectInfo",
      "projectRooms",
      "activityList"
    ]),
    pollActivities: function() {
      return this.activityList.filter(function(activity) {
        return activity.system_activity_type_id == 9;
      });
    },
    nonPollActivities: function() {
      return this.activityList.filter(function(activity) {
        return activity.system_activity_type_id != 9;
      });
    },
    roomlessActivityCount: function() {
      let roomlessActivities = this.activityList.filter(function(activity) {
        return activity.project_room_id == null;
      });
      return roomlessActivities.length;
    }
  }
};
</script>
