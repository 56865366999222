<template>
  <div :id="'activity-room-row-' + activity.id" class="my-2">
    <tr
      v-bind:style="[
        noQuestions
          ? {
              'background-color': '#ff00001c !important',
              border: '2px solid #c9000057'
            }
          : null
      ]"
      style="border: 1px solid"
      v-on:mouseover="optionsEnabled = 1"
      v-on:mouseleave="onMouseLeft()"
      class="border-light"
    >
      <td
        class="pt-5 text-right"
        :class="{ 'bg-white' : activity.sequence_parent != null && !noQuestions, 'bg-light' : hasChildren && !noQuestions}"
        style="width: 35px"
      >
        <i
          :class="icon"
          v-b-tooltip.hover.right
          :title="$t(iconLabel)"
        >
        </i>
      </td>
      <td
        class="px-2 py-3 d-table-cell"
        :class="{ 'bg-white' : activity.sequence_parent != null && !noQuestions, 'bg-light' : hasChildren && !noQuestions}"
        style="width: 800px"
      >
        <router-link 
          v-if="currentUser.menu_items.includes('AddEntity')"
          :to="{
            name: (activity.system_activity_type_id === 8) ? `edit_markup_legacy` : `edit_${activity.activity_type.name
              .toLowerCase()
              .replace(/ /g, '_')
              .replace('(','')
              .replace(')','')
            }`,
            params: { activity: activity.id }
          }"
          class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
        >
          {{ activity.title }}
          <span
            v-if="activity.featured === 1"
            class="bg-white label label-pill label-inline ml-3 label-outline-primary"
          >
            {{ $t('FORMS.INPUTS.ACTIVITY_FEATURED_SHORT') }}
          </span>
        </router-link>
        <span
          v-else
          class="text-dark-65 font-weight-bolder mb-1 font-size-sm"
        >
          {{ activity.title }}
          <span
            v-if="activity.featured === 1"
            class="bg-white label label-pill label-inline ml-3 label-outline-primary"
          >
            {{ $t('FORMS.INPUTS.ACTIVITY_FEATURED_SHORT') }}
          </span>
        </span>
        <span
          v-if="noQuestions"
          class="text-danger font-weight-bold font-size-sm ml-2"
        >
          {{ $t("GENERAL.TEXT.NO_QUESTIONS") }}
        </span>
        <span class="d-block">
          <p
            :id="'allocation_text' + activity.id"
            class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
            style="cursor:pointer !important"
          >
            {{ activityAllocationText }}
          </p>
          <p
            class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mx-1 mb-0"
          >
            -
          </p>
          <p
            class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
          >
            {{ $t("GENERAL.TEXT.LAST_UPDATE") }}: 
            <GenericDatetimeDisplay
              :date="activity.updated_at"
              :lastUpdated="true"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </p>
        </span>
      </td>
      <td
        class="px-2 py-3 d-table-cell"
        :class="{ 'bg-white' : activity.sequence_parent != null && !noQuestions, 'bg-light' : hasChildren && !noQuestions}"
        style="width:350px"
      >
        <div
          v-if="isScheduledActivity"
          :id="'date_text_scheduled' + activity.id"
          class="d-inline-block"
          style="cursor:pointer"
        >
          <span
            class="d-block text-dark-65 mb-1 font-size-sm"
            :class="formattedDates.stageClass"
          >
            <strong v-show="!this.projectInfo.isTemplate" >{{ $t("GENERAL.PREFIX.SCHEDULED_FROM") }}</strong>
            <GenericDatetimeDisplay
              v-show="!this.projectInfo.isTemplate"
              :long="true"
              :date="activity.starts_on"
              :lastUpdated="false"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
        </div>
        <div
          v-else-if="isSequencedActivity"
          :id="'date_text_sequenced' + activity.id"
          class="d-inline-block"
          style="cursor:pointer"
        >
          <span
            class="d-block text-dark-65 mb-1 font-size-sm"
            :class="formattedDates.stageClass"
          >
            <strong>{{ $t("GENERAL.TEXT.SEQUENCED") }}</strong>
          </span>
        </div>
        <div
          v-else
          :id="'date_text' + activity.id"
          class="d-inline-block"
          style="cursor:pointer"
        >
          <span
            class="d-block text-dark-65 mb-1 font-size-sm"
            :class="formattedDates.stageClass"
          >
            <strong v-show="!this.projectInfo.isTemplate">{{ formattedDates.startPrefix }}</strong>
            <GenericDatetimeDisplay
              v-show="!this.projectInfo.isTemplate"
              :long="true"
              :date="activity.starts_on"
              :lastUpdated="false"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
          <span
            class="d-block text-dark-65 mb-1 font-size-sm"
            :class="formattedDates.stageClass"
          >
            <strong v-show="!this.projectInfo.isTemplate">{{ formattedDates.endPrefix }}</strong>
            <GenericDatetimeDisplay
              v-show="!this.projectInfo.isTemplate"
              :long="true"
              :date="activity.ends_on"
              :lastUpdated="false"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
        </div>
      </td>
      <td
        class="px-2 text-right d-table-cell"
        :class="{ 'bg-white' : activity.sequence_parent != null && !noQuestions, 'bg-light' : hasChildren && !noQuestions}"
        style="width: 565px"
      >
        <div v-show="optionsEnabled" class="pt-1 px-3">
          <a
            :href="testActivityLink" 
            class="btn btn-icon btn-light btn-hover-primary btn-sm"
            :class="(isSequencedActivity || isScheduledActivity || hasChildren) ? 'mx-5' : 'mx-1'"
            target="_blank"
            v-b-tooltip.hover.bottom
            :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.TEST')"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/Media/Play.svg" />
            </span>
          </a>
          <span
            v-show="(isSequencedActivity || isScheduledActivity || hasChildren) &&
                      currentUser.menu_items.includes('AddEntity')"
          >
            <a
              v-show="
                activity.system_activity_type_id != 7 &&
                  (activity.sequence_parent != null ||
                    activity.sequence_children > 0)
              "
            >
              <b-dropdown
                size="sm"
                class="sequence-options btn btn-icon btn-light btn-sm"
                v-b-tooltip.hover.top
                right
                :ref="'sequence-dropdown-' + activity.id"
                :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.SEQUENCE_OPTIONS')"
              >
                <template #button-content>
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="media/svg/icons/Home/Commode2.svg"/>
                  </span>
                </template>
                <b-dropdown-item-button  
                  @click="sequenceActivity()"
                > 
                  <span class="svg-icon svg-icon-md svg-icon-primary mr-2">
                    <inline-svg src="media/svg/icons/Code/Plus.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.SEQUENCE_ANOTHER') }} </span>
                </b-dropdown-item-button>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item-button
                  v-if="hasChildren && !isSequencedActivity && !isScheduledActivity"  
                  @click="duplicateActivity(true)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary mr-2">
                    <inline-svg src="media/svg/icons/Design/Substract.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DUPLICATE_SEQUENCE') }} </span>
                </b-dropdown-item-button>
                <b-dropdown-divider v-if="hasChildren && !isSequencedActivity && !isScheduledActivity"></b-dropdown-divider>
                <b-dropdown-item-button
                  @click="removeFromSequence()"
                > 
                  <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                    <inline-svg src="media/svg/icons/Navigation/Minus.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.REMOVE_FROM_SEQUENCE') }} </span>
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-if="hasChildren && (isSequencedActivity || isScheduledActivity)"
                  @click="removeAllFromSequence()"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                    <inline-svg src="media/svg/icons/Home/Library.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.REMOVE_ALL_FROM_SEQUENCE') }} </span>
                </b-dropdown-item-button>
                <b-dropdown-divider v-if="hasChildren"></b-dropdown-divider>
                <b-dropdown-item-button
                  v-if="hasChildren && !isSequencedActivity && !isScheduledActivity"
                  @click="deleteSequence()"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                    <inline-svg src="media/svg/icons/General/Trash.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DESTROY_SEQUENCE') }} </span>
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-if="(isSequencedActivity || isScheduledActivity) && hasChildren"
                  @click="deleteSequence()"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger mr-2">
                    <inline-svg src="media/svg/icons/Code/Error-circle.svg"/>
                  </span>
                  <span class="mt-3"> {{ $t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DESTROY_SEQUENCE_CHILD') }} </span>
                </b-dropdown-item-button>
              </b-dropdown>
            </a>
          </span>
          <a
            v-show="
              activity.system_activity_type_id != 7 &&
                activity.sequence_children == 0 &&
                activity.sequence_parent == null &&
                currentUser.menu_items.includes('AddEntity')
            "
            href="javascript:void(0)"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            v-b-tooltip.hover.bottom
            @click="sequenceActivity()"
            :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.SEQUENCE')"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/Navigation/Plus.svg" />
            </span>
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            v-if="currentUser.menu_items.includes('AddEntity')"
            v-b-tooltip.hover.bottom
            @click="duplicateActivity(false)"
            :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DUPLICATE')"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/General/Duplicate.svg" />
            </span>
          </a>
          <a
            href="javascript:void(0)"
            class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
            v-if="currentUser.menu_items.includes('AddEntity')"
            v-b-tooltip.hover.bottom
            :title="$t('TABLES.ACTIVITY_BREAKDOWN_TOOLTIPS.DESTROY')"
            @click="deleteActivity()"
          >
            <span class="svg-icon svg-icon-md svg-icon-danger">
              <inline-svg src="media/svg/icons/Home/Trash.svg" />
            </span>
          </a>
        </div>
      </td>

      <b-tooltip
        v-if="activity.project_segments.length > 0"
        :ref="'tooltip' + activity.id"
        :target="'allocation_text' + activity.id"
        placement="right"
      >
        <template v-for="(project_segment, i) in activity.project_segments">
          <div :key="i">{{ project_segment.name }}<br /></div>
        </template>
      </b-tooltip>

      <b-tooltip
        v-if="activity.project_segments.length == 0"
        :ref="'tooltip' + activity.id"
        :target="'allocation_text' + activity.id"
        placement="right"
      >
        {{ $t("TABLES.ACTIVITY_BREAKDOWN_TEXT.OPEN_TO_ALL") }}
      </b-tooltip>

      <b-tooltip
        :ref="'tooltip_dates' + activity.id"
        :target="'date_text' + activity.id"
        placement="right"
      >
        {{ formattedDates.tooltip }}
      </b-tooltip>
    </tr>
    <ActivitySequence
      v-if="hasChildren"
      :children="activity.child_activities"
      :activityId="activity.id"
      @open_sequence_modal="$emit('open_sequence_modal', $event)"
      @activity_list_edited="$emit('activity_list_edited')"
    >
    </ActivitySequence>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  DELETE_PROJECT_ACTIVITY,
  DELETE_PROJECT_ACTIVITY_SEQUENCE,
  UPDATE_PROJECT_ACTIVITY_SEQUENCE
} from "@/core/services/store/activity/activity.module";

import ActivitySequence from "@/view/content/activities/ActivitySequence";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "ActivityRoomRow",

  props: ["activity","index"],

  data() {
    return {
      optionsEnabled: 0
    };
  },

  components: { ActivitySequence, GenericDatetimeDisplay },

  methods: {
    onMouseLeft() {
      this.optionsEnabled = 0;
      this.$refs['sequence-dropdown-' + this.activity.id].hide(true);
    },
    sequenceActivity() {
      this.$emit("open_sequence_modal", this.activity.id);
    },
    duplicateActivity(sequence) {
      if (sequence == false) {
        let duplicateRoute =
          "duplicate_" + ((this.activity.recording_override) ? 'screen_recording' : this.activity.activity_type.name)
            .toLowerCase()
            .replace("(","")
            .replace(")","")
            .split(" ")
            .join("_");

        this.$router.push({
          name: duplicateRoute,
          params: { activity: this.activity.id }
        });
      } else {
        this.$emit("duplicate_sequence", this.activity);
      }
    },
    deleteSequence() {
      this.$swal
        .fire({
          title: `${this.$t(
            "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.TITLE"
          )}`,
          text:
            this.activity.title +
            " " +
            `${this.$t(
              "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.MESSAGE"
            )}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: this.activity.id
            };
            this.$store
              .dispatch(DELETE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_SUCCESS.TITLE"
                  )}`,
                  text:
                    this.activity.title +
                    " " +
                    `${this.$t(
                      "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_SUCCESS.MESSAGE"
                    )}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_SEQUENCE_DELETE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    removeFromSequence() {
      this.$swal
        .fire({
          title: `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.TITLE")}`,
          text:
            this.activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: this.activity.id,
              data: {
                remove_all: false
              }
            };
            this.$store
              .dispatch(UPDATE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_SUCCESS.TITLE"
                  )}`,
                  text:
                    this.activity.title +
                    " " +
                    `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_SUCCESS.MESSAGE")}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    removeAllFromSequence() {
      this.$swal
        .fire({
          title: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.TITLE"
          )}`,
          text:
            this.activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_REMOVE_ALL_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              activityId: this.activity.id,
              data: {
                remove_all: true
              }
            };
            this.$store
              .dispatch(UPDATE_PROJECT_ACTIVITY_SEQUENCE, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_ALL_SUCCESS.TITLE"
                  )}`,
                  text:
                    this.activity.title +
                    " " +
                    `${this.$t(
                      "SWEETALERTS.ACTIVITY_REMOVE_ALL_SUCCESS.MESSAGE"
                    )}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_REMOVE_ALL_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    },
    deleteActivity() {
      this.$swal
        .fire({
          title: `${this.$t("SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.TITLE")}`,
          text:
            this.activity.title +
            " " +
            `${this.$t("SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.MESSAGE")}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${this.$t(
            "SWEETALERTS.ACTIVITY_DELETE_CHALLENGE.CONFIRM"
          )}`,
          cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
        })
        .then(result => {
          if (result.isConfirmed) {
            let payload = {
              projectId: this.activity.project_id,
              activityId: this.activity.id
            };
            this.$store
              .dispatch(DELETE_PROJECT_ACTIVITY, payload)
              .then(() => {
                this.$emit("activity_list_edited");
                this.$swal.fire({
                  title: `${this.$t(
                    "SWEETALERTS.ACTIVITY_DELETE_SUCCESS.TITLE"
                  )}`,
                  text:
                    this.activity.title +
                    " " +
                    `${this.$t("SWEETALERTS.ACTIVITY_DELETE_SUCCESS.MESSAGE")}`,
                  icon: "success",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
                  text: `${this.$t(
                    "SWEETALERTS.ACTIVITY_DELETE_FAIL.MESSAGE"
                  )}`,
                  icon: "error",
                  confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
                });
              });
          }
        });
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo"
    ]),
    testActivityLink: function() {
      return this.projectInfo.inputLink + "/#/activity/" + this.activity.id + "/view";
    },
    icon: function() {
      return SystemIconHelper.activityType(this.activity.activity_type, this.activity.recording_override);
    },
    iconLabel: function() {
      return SystemLabelHelper.activityType(this.activity.activity_type, this.activity.recording_override);
    },
    activityAllocationText: function() {
      if (this.activity.project_segments.length == 0) {
        return "All Participants";
      } else {
        return "Allocation (" + this.activity.project_segments.length + ")";
      }
    },
    formattedDates: function() {
      let tooltip = "";
      let stageClass = "";
      let startPrefix = "";
      let endPrefix = "";
      switch (this.activity.activity_stage.name) {
        case "Draft":
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT_EXTENDED")}`;
          stageClass = "";
          startPrefix = `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          endPrefix = `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case "Published":
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.PUBLISHED_EXTENDED")}`;
          stageClass = "";
          startPrefix = `${this.$t("GENERAL.PREFIX.STARTS_ON")}: `;
          endPrefix = `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case "Active":
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.ACTIVE_EXTENDED")}`;
          stageClass = "text-success";
          startPrefix = `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          endPrefix = `${this.$t("GENERAL.PREFIX.ENDS_ON")}: `;
          break;
        case "Closed":
          tooltip = `${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED_EXTENDED")}`;
          stageClass = "text-danger";
          startPrefix = `${this.$t("GENERAL.PREFIX.STARTED_ON")}: `;
          endPrefix = `${this.$t("GENERAL.PREFIX.ENDED_ON")}: `;
          break;
      }
      return {
        tooltip: tooltip,
        stageClass: stageClass,
        startPrefix: startPrefix,
        endPrefix: endPrefix,
      };
    },
    hasChildren() {
      if (this.activity.sequence_children > 0) {
        return true;
      } else {
        return false;
      }
    },
    isSequencedActivity() {
      if (this.activity.sequence_type == 1) {
        return true;
      } else {
        return false;
      }
    },
    isScheduledActivity() {
      if (this.activity.sequence_type == 2) {
        return true;
      } else {
        return false;
      }
    },
    noQuestions() {
      if (
        (this.activity.system_activity_type_id == 2 &&
          this.activity.activity_diary_questions.length == 0) ||
        (this.activity.system_activity_type_id == 4 &&
          this.activity.activity_survey_questions.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
