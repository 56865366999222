<template>
  <b-dropdown
    id="filter-activity-stage"
    size="sm"
    :text="$t('SYSTEM.ACTIVITY_STAGE.' + selectedStageName.toUpperCase())"
    :variant="selectedStageVariant"
    class="table-filter"
    right
  >
    <b-dropdown-item @click="emitChange(null)">
      {{ $t('SYSTEM.ACTIVITY_STAGE.ANY') }}
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item
      v-for="(systemActivityStage, i) in systemActivityStages"
      :key="i"
      @click="emitChange(systemActivityStage)"
    >
      {{ $t('SYSTEM.ACTIVITY_STAGE.' + systemActivityStage.name.toUpperCase() ) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemActivityStageFilter",

  props: {
    selected: { type: Number, required: false }
  },

  methods: {
    emitChange: function(stage = null) {
      if (stage != null) {
        this.$emit('stage_changed',stage.id);
      } else {
        this.$emit('stage_changed',null);
      }
    }
  },

  computed: {
    ...mapGetters(["systemActivityStages"]),

    selectedStageName: function() {
      let selected = this.selected;
      if (this.selected != undefined && this.selected != null) {
        let stage = this.systemActivityStages.filter(function (stage) { return stage.id == selected; });
        return stage[0].name;
      } else {
        return 'any';
      }
    },

    selectedStageVariant: function() {
      let variant = null;

      if (this.selected != undefined && this.selected != null) {
        switch(this.selected) {
          case 1:
            variant = 'primary'
            break;
        }
      } else {
        variant = 'primary';
      }

      return variant;
    }
  }
};
</script>
