<template>
  <div class="col-xxl-12 px-0">
    <div
      class="card card-custom card-stretch gutter-b"
      :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
    >
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t('SYSTEM.ACTIVITY_TYPE.POLL2') }}
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-if="loadingComplete && currentUser.menu_items.includes('AddEntity')"
            tag="button"
            class="btn btn-primary btn-sm"
            :to="{ name: 'add_poll' }"
          >
            {{ $t('GENERAL.BUTTONS.ADD') + ' ' + $t('SYSTEM.ACTIVITY_TYPE.POLL') }}
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <b-spinner
          v-if="!loadingComplete && !error.active"
          variant="primary"
          label="Spinning"
          class="card-loader"
        >
        </b-spinner>
        <table
          v-else
          class="table table-head-custom table-vertical center table-hover"
          style="table-layout:auto; overflow: auto"
        >
          <thead>
            <tr>
              <th class="px-2 py-3" style="min-width:250px">
                {{ $t('TABLES.POLL_HEADERS.TITLE') }}
              </th>
              <th class="px-2 py-3 text-left" style="min-width:150px">
                {{ $t('TABLES.POLL_HEADERS.TYPE') }}
              </th>
              <th class="px-2 py-3 text-left" style="min-width:120px">
                {{ $t('TABLES.POLL_HEADERS.CREATED_AT') }}
              </th>
              <th class="px-2 py-3 text-left" style="min-width:120px">
                {{ $t('TABLES.POLL_HEADERS.UPDATED_AT') }}
              </th>
              <th class="pr-4 text-right" style="width:150px">
                {{ $t('TABLES.POLL_HEADERS.ACTIONS') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(activity, a) in pollActivities">
              <PollActivityRow
                :key="a"
                :activity="activity"
                @activity_list_edited="activitiesChanged"
              >
              </PollActivityRow>
            </template>
            <tr v-if="pollActivities.length == 0 && loadingComplete">
              <td class="px-2 text-center" colspan="5">
                <span class="text-dark-65 font-weight-bold d-block py-3">
                  {{ $t('TABLES.POLL_BREAKDOWN_MESSAGES.NO_POLLS_TEXT') }}
                  <router-link
                    v-if="currentUser.relation_to_project != 'observer'"
                    tag="a"
                    class="text-primary"
                    :to="{ name: 'add_poll' }"
                  >
                    {{ $t('TABLES.POLL_BREAKDOWN_MESSAGES.NO_POLLS_LINK') }}
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <b-alert v-if="error.active" show variant="light">
          {{ error.message }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PollActivityRow from "@/view/content/activities/PollActivityRow";

export default {
  name: "ActivityPollBreakdown",

  props: [
    "pageLoader",
    "error",
    "pollActivities"
  ],

  components: { PollActivityRow },

  methods: {
    activitiesChanged() {
      this.$emit('activity_list_changed');
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired &&
        this.error.active == false &&
        this.pageLoader.fetchingActivities == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
