<template>
  <tr
    v-on:mouseover="optionsEnabled = 1"
    v-on:mouseleave="optionsEnabled = 0" 
  >
    <td class="px-2 py-5 d-table-cell">
      <router-link
        v-if="currentUser.menu_items.includes('AddEntity')"
        tag="a"
        :to="{ name: 'edit_poll', params: { activity: activity.id }}"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
      >
        {{ activity.title | stripHTML }}
      </router-link>
      <span
        v-else
        class="text-dark-65 font-weight-bolder mb-1 font-size-sm"
      >
        {{ activity.title | stripHTML }}
      </span>
    </td>
    <td class="py-5" style="min-width:175px">
      <p
        class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
      >
        {{ $t('SYSTEM.ANSWER_TYPE.' + activity.activity_poll_question.system_answer_type_id) }}
      </p>
    </td>
    <td class="py-5" style="min-width:175px">
      <p
        class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
      >
        <GenericDatetimeDisplay
          :long="true"
          :date="activity.created_at"
          :createdAt="true"
          :lastUpdated="false"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </p>
    </td>
    <td class="py-5" style="min-width:175px">
      <p
        class="d-inline-block text-muted font-weight-bold text-muted font-size-sm mb-0"
      >
        <GenericDatetimeDisplay
          :long="true"
          :date="activity.updated_at"
          :lastUpdated="true"
          :relative="false"
        >
        </GenericDatetimeDisplay>
      </p>
    </td>
    <td class="pr-0 py-0 text-right d-table-cell" style="min-width:150px">
      <div v-if="optionsEnabled" class="pt-1 px-3">
        <a
          v-if="currentUser.menu_items.includes('AddEntity')"
          href="javascript:void(0)"
          class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1 mt-2"
          v-b-tooltip.hover.bottom
          :title="$t('TABLES.POLL_BREAKDOWN_TOOLTIPS.DESTROY')"
          @click="deleteActivity()"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <inline-svg src="media/svg/icons/Home/Trash.svg" />
          </span>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { DELETE_PROJECT_ACTIVITY } from "@/core/services/store/activity/activity.module";

import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

export default {
  name: "PollActivityRow",

  props: ["activity"],

  data() {
    return {
      optionsEnabled: 0
    }
  },

  components: {
    GenericDatetimeDisplay
  },

  methods: {
    deleteActivity() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.POLL_DELETE_CHALLENGE.TITLE")}`,
        text: this.activity.title.replace(/(<([^>]+)>)/gi, "") + " " + `${this.$t("SWEETALERTS.POLL_DELETE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.POLL_DELETE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            projectId: this.activity.project_id,
            activityId: this.activity.id
          };
          this.$store.dispatch(DELETE_PROJECT_ACTIVITY, payload).then(() => {
            this.$emit("activity_list_edited");
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.POLL_DELETE_SUCCESS.TITLE")}`,
              text: this.activity.title.replace(/(<([^>]+)>)/gi, "") + ' ' + `${this.$t("SWEETALERTS.POLL_DELETE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
          .catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.POLL_DELETE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      })
    },
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ])
  }
}
</script>
