<template>
    <DraggableActivity
      v-model="elements"
      :generation="generation"
      :activityId="activityId"
      @open_sequence_modal="$emit('open_sequence_modal', $event)"
      @activity_list_edited="$emit('activity_list_edited')"
    >
    </DraggableActivity>
</template>

<script>
import DraggableActivity from "@/view/content/activities/DraggableActivity";
import {
  UPDATE_SEQUENCED_ACTIVITY_LIST,
  UPDATE_ELEMENTS
} from "@/core/services/store/activity/activity.module";

export default {
  name: "ActivitySequence",

  props: {
    children: { type: Array, required: true },
    activityId: { type: Number, required: true }
  },

  data() {
    return {
      generation: 1
    };
  },

  watch: {
    elements: {
      handler() {
        this.updateSequence()
      },
      deep: true
    }
  },

  components: {
    DraggableActivity,
  },

  methods: {
    updateSequence() {
      let payload = {
        activityId: this.activityId,
        data: {
          value: this.elements
        }
      };
      
      this.$store.dispatch(UPDATE_SEQUENCED_ACTIVITY_LIST, payload);
    }
  },

  computed: {
    elements: {
      get() {
        return this.children;
      },
      set(value) {
        let payload = {
          activityId: this.activityId,
          data: value
        };

        this.$store.dispatch(UPDATE_ELEMENTS, payload);
      }
    }
  }
};
</script>
